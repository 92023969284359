import React from 'react'
import Intro from '../../components/Intro/index'
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

const Donate = () => {
  return (
    <>
       <Header />
      <Intro title="Join hands with us" bgimg="./images/kidimg1.jpg" />
      <main>
        <div className='container'>
      
<form method="POST" action="https://checkout.flutterwave.com/v3/hosted/pay">
  <input type="hidden" name="public_key" value="FLWPUBK-f602e776da555232473f1db6ca870850-X" />
  <label>Fullname</label>
  <input type="text" name="customer[name]" placeholder='Fullname:' />
  <label>Email</label>
  <input type="text" name="customer[email]" placeholder='Email Address'  />
  <input type="hidden" name="tx_ref" value="bitethtx-019203" />
  <label>Amount</label>
  <input type="number" name="amount" placeholder='Donate Amount:'  />
  <label>Currency</label>
  <input type="text" name="currency" placeholder='Currency' />
  <input type="hidden" name="meta[token]" value="54" />
  <input type="hidden" name="redirect_url" value="https://damucharityfoundation.org/success" />
  <button type="submit" className='btn' id="start-payment-button">Pay Now</button>
</form>
</div>
      </main>
      <Footer />
    </>
  )
}

export default Donate



