import React, { useEffect, useState } from 'react';
import SliderContent from './SliderContent';
import Arrows from './Arrows';
import imageSlider from './images';
import Dots from './Dots';
import './slider.css'

const len = imageSlider.length - 1;

const Slider = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(()=>{
        const interval = setInterval(() => {
            setActiveIndex(activeIndex ===len?0:activeIndex + 1)
        },7000);
        return ()=>{
            clearInterval(interval)
        }
    },[activeIndex])
    return (
        <section className='slider-container '>
            <SliderContent
                activeIndex={activeIndex}
                imageSlider={imageSlider}
            />
            <Arrows
                prevSlide={() =>
                    setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
                }
                nextSlide={() =>
                    setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
                }
            />
            <Dots activeIndex={activeIndex} imageSlider={imageSlider} onClick={(activeIndex)=>setActiveIndex(activeIndex)} />
        </section>
    );
};

export default Slider;
