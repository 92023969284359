import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Intro from '../../components/Intro/index'
import Map from '../../components/map/Map'

const Contact=()=>{
  return (
      <>
          <Header />
           <Intro title="Contact Us" bgimg="./images/img1.jpg" />
          <main>
            <section className='contacts'>
                    <h2>
                        Contact Us <span></span>
                    </h2>
                    <h3>Visit us today</h3>
                    <div className='container'>
                        <form action='#'>
                            <input type='text' placeholder='Fullname' name='fullname' id='' />
                             <input type='email' placeholder='Email' name='email' id='' />
                            <input type='tel' placeholder='Mobile' name='mobile' id='' />
                            <label>Message</label>
                            <textarea name="message" id="" cols="30" rows="10"></textarea>
                            <button className='btn'>Submit</button>
                        </form>
                        <div cl></div>
                    </div>
                </section>
          </main>
          <Map />
          <Footer />
      </>
  );
}

export default Contact;
