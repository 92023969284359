import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Intro from '../../components/Intro/index'


function Team() {
  return (
      <>
          <Header />
           <Intro title="Our Team" bgimg="./images/gpimg1.jpg" />
          <main>
             <div className="container">
                          <p>We have a strong team that includes teachers, engineers, couselors and doctors who train the kids in various aspects of life.</p>

             </div>
          </main>
          <Footer />
      </>
  );
}

export default Team;
