import './footer.css';
const Footer =()=>{
  return (
      <footer data-testid='footer-1'>
          <section className='top-footer'>
              <div className='container'>
                  <div className='about-footer'>
                      <h3>About Damu Charity</h3>
                      <p>
                          DCF is a child focused initiative which is local, national, international NGO with its head office in jinja, Butagaya sub-county, Nawampanda parish and Nabukosi village. 
                      </p>
                      <div className='social-media'>
                          <ul>
                              <li>
                                 <a target='blank' href="https://www.facebook.com/people/Damu-charity-foundation/100070015436479/"><i className='fa fa-facebook-f'></i></a> 
                              </li>
                              <li>
                                  <a target='blank' href="https://twitter.com/damuliraibrahim?lang=en"><i className='fa fa-twitter'></i></a> 
                              </li>
                              <li>
                                  <a target='blank' href="https://linkedin.com/"><i className='fa fa-linkedin'></i></a>
                              </li>
                              <li>
                                   <a target='blank' href="https://www.instagram.com/damu_charity_foundation/"><i className='fa fa-instagram'></i></a> 
                              </li>
                              <li>
                                  <a target='blank' href="https://youtube.com/"> <i className='fa fa-youtube-play'></i></a> 
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div className='useful-links'>
                      <h3>Usefull Links</h3>
                      <ul>
                          <li>Home</li>
                          <li>Who we are</li>
                          <li>Experience</li>
                          <li>Team</li>
                          <li>Contact Us</li>
                      </ul>
                  </div>
                  <div className='get-intouch'>
                      <h3>Get intouch</h3>
                      <p>
                      P.O Box 428,<br/>
                      Jinja(Uganda)<br/>
                      East Africa- Africa<br/>
                      Tel: +256785124031 <br/>+256750006975<br/>
                      </p>

                  </div>
              </div>
          </section>
          <section className='bottom-footer'>
              <div className='containerDCF is a child focused initiative which is local, national, international NGO with its head office in jinja, Butagaya sub-county, Nawampanda parish and Nabukosi village. '>
                  <div className='copy-right'>
                      © 2022 Damu Charity Foundation. All Rights Reserved
                  </div>
                  <div className='privacy-policy'>Privacy policy</div>
              </div>
          </section>
      </footer>
  );
}

export default Footer;
