import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Intro from '../../components/Intro/index'


function Volunteer() {
  return (
      <>
          <Header />
           <Intro title="Volunteer with Us" bgimg="./images/gpimg3.jpg" />
          <main>
              <section className='contacts'>
                    <h2>
                        Join Us <span></span>
                    </h2>
                    <div className='container'>
                        <form action='#'>
                            <input type='text' placeholder='Fullname' name='fullname' id='' />
                             <input type='email' placeholder='Email' name='email' id='' />
                            <input type='tel' placeholder='Mobile' name='mobile' id='' />
                             <input type='text' placeholder='Country' name='country' id='' />
                              <input type='text' placeholder='City' name='city' id='' />
                            <label>Why did you decide to join us</label>
                            <textarea name="why" id="" cols="10" rows="5"></textarea>
                            <button className='btn'>Submit</button>
                        </form>
                        <div cl></div>
                    </div>
                </section>
          </main>
          <Footer />
      </>
  );
}

export default Volunteer;
