import React from 'react';
import { Link } from 'react-router-dom';


const SliderContent = ({ activeIndex, imageSlider }) => {
    return (
        <section>
            {imageSlider.map((slide, index) => {
                return (
                    <div
                        key={index}
                        className={
                            index === activeIndex ? 'slides active' : 'inactive'
                        }
                    >
                        <img
                            className='slide-img'
                            src={slide.urls}
                            alt={slide.title}
                        />
                        <div className='desc'>
                            <h2 className='slide-title'>{slide.title}</h2>
                            <h3 className='slide-text'>{slide.description}</h3>
                            <Link to='/donate'><button className='btn'>Donate</button></Link>
                            <button className='btn-neg'>Volunteer with us</button>
                        </div>
                    </div>
                );
            })}
        </section>
    );
};

export default SliderContent;
