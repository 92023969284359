import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Intro from '../../components/Intro/index'
import './projects.css'

function Projects() {
  return (
      <>
          <Header />
           <Intro title="Our Projects" bgimg="./images/gpimg2.jpg" />
          <main className='projects'>
             <div className='container'>
                        <div className='cause' >
                            <img src='./images/kidimg1.jpg' alt=''/>
                            <h3>Kids to School Project</h3>
                            <p>foster and promote good heath, psychological and social economic prosperity of orphans while 
                            protecting their rights</p>
                        </div>
                         <div className='cause' >
                            <img src='./images/kidimg1.jpg' alt=''/>
                            <h3>Poverty Eradication Project</h3>
                            <p>We help parents start businesses and provide startup items like seeds, animals or even startup capital.</p>
                        </div>
                         <div className='cause' >
                            <img src='./images/kidimg1.jpg' alt=''/>
                            <h3>Career Guidence</h3>
                            <p>We provide kids with guidence from trained professionals in </p>
                        </div>
                    </div>
          </main>
          <Footer />
      </>
  );
}

export default Projects;
