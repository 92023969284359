import { Routes, Route } from 'react-router-dom';
import Index from './pages/index/Index';
import About from './pages/about/About'
import Projects from './pages/projects/Projects'
import Team from './pages/team/Team'
import Contacts from './pages/contact/Contact'
import Volunteer from './pages/volunteer/Volunteer';
import Donate from './pages/donate/donate';
import Donatethanks from './pages/donate/donatethanks';
import './App.css';

const App =()=> {
  return (
      <>
          <Routes>
              <Route exact path='/' element={<Index />} />
              <Route path='/about' element={<About />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/team' element={<Team />} />
              <Route path='/volunteers' element={<Volunteer />} />
              <Route path='/contact' element={<Contacts />} />
               <Route path='/donate' element={<Donate />} />
               <Route path='/success' element={<Donatethanks />} />
          </Routes>
      </>
  );
}

export default App;
