import { compose, withProps } from 'recompose';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyCK6M79qZsGKFLNnu7L3q_uUYe_2_u3hJI&v=3.exp&libraries=geometry,drawing',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        defaultZoom={13}
        defaultCenter={{
            lat: 0.5688590635890663,
            lng: 33.08999727461837
        }}
    >
        {
            <Marker position={{ lat: 0.5688537, lng: 33.0925722}} />
        }
    </GoogleMap>
));

export default MyMapComponent