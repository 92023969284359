import React from 'react'
import styled from 'styled-components'
import { Parallax, Background} from 'react-parallax';
import '../Intro/index.css'

const Space = (props) => {

  const content = props.content
  const bgimg = props.bgimg
  return (
    <>
      <Section>
        <Parallax strength={300}>
        <Background >
            <BgImg src={bgimg} alt="" />
             <Content>
               <h3>{content}</h3>
             </Content>
        </Background>
         
        </Parallax> 
      </Section>
    </>
  )
}

export default Space

const Section = styled.section`
    width: 100%;
    background-color:#000;
    height: 300px;
    overflow: hidden;
`
const BgImg = styled.img`
  width: 100%;
  
`
const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 500px;
  padding: 150px 10px;
  background-color: rgba(0,0,0,0.5);
  color: #Fff !important;
  overflow: none;
  text-align: center;
  top: 0;
  z-index:'20';
`