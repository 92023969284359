import React from 'react'
import Intro from '../../components/Intro/index'
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

const Donatethanks = () => {
  return (
    <>
       <Header />
      <Intro title="success" bgimg="./images/kidimg1.jpg" />
      <main>
        <div className='container'>
           <p style={{color:"green"}}>Your Donation has been recieved</p>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Donatethanks