import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Intro from '../../components/Intro/index'

function About() {
  return <>
      <Header />
            <Intro title="About Us" subtitle="who we are" bgimg="./images/gpimg3.jpg" />
           <main>
            <div className='container'>
                <p>
                  DCF is a child focused initiative which is local, national, international NGO with its head office
                  in jinja, Butagaya sub-county, Nawampanda parish and Nabukosi village.
                  The organisation was started after the realization of communities being faced with a bg
                  challenge of orphans and vulnerable children who are not accessing formal education and living
                  in appaling situation due to HIV/AIDS scourge.
                    </p>
                    <p>
                      We belive that the most viable way to liberate the orphans and valnerable children, street children is to directly
                      invest in their formal education for this will widen their horisons and opportunities.
                    </p>

                      <h3>Mission</h3>
                  <blockquote>
                      To reach out to all kinds of HIV/AIDS affected people in both rural and urban communities to build
                      hope, foster and promote good heath, psychological and social economic prosperity of orphans while 
                      protecting their rights
                  </blockquote>
                    <h3>Vision</h3>
                  <blockquote>
                      To reach out to all kinds of HIV/AIDS affected people in both rural and urban communities to build
                      hope, foster and promote good heath, psychological and social economic prosperity of orphans while 
                      protecting their rights
                  </blockquote>
            </div>
           
           </main>
      <Footer/>
  </>;
}

export default About;
