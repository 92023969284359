import First from '../../assets/2-d.jpg';
import Second from '../../assets/3-d.jpg';

const imgArry = [
    {
        title: 'Join hands and get children off the streets',
        description:
            'We must work together to build a kinder fairer, more inclusive world for every child with or without a disability.',

        urls: First,
    },
    {
        title: 'A child has a right to education',
        description:
             'We can join hand and help these kids. Education is a human right.',

        urls: Second,
    },
   
];
export default imgArry