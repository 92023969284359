import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Slider from '../../components/slider/Slider';
import Space from '../../components/SpaceSection/space'
import Map from '../../components/map/Map'
import './index.css';

function Index() {
    return (
        <>
            <Header />
            <main>
                <Slider />
                <section className='about'>
                    <h2>
                        Who we are <span></span>
                    </h2>
                    <h3>Come work with us</h3>
                    <div className='container'>
                        <p>
                        DCF is a child focused initiative which is local, national, international NGO with its head office
                        in jinja, Butagaya sub-county, Nawampanda parish and Nabukosi village.
                        The organisation was started after the realization of communities being faced with a bg
                        challenge of orphans and vulnerable children who are not accessing formal education and living
                        in appaling situation due to HIV/AIDS scourge.
                         </p>
                         <p>
                            We belive that the most viable way to liberate the orphans and valnerable children, street children is to directly
                            invest in their formal education for this will widen their horisons and opportunities.
                         </p>
                        <h3>Mission</h3>
                        <blockquote>
                            To reach out to all kinds of HIV/AIDS affected people in both rural and urban communities to build
                            hope, foster and promote good heath, psychological and social economic prosperity of orphans while 
                            protecting their rights
                        </blockquote>
                    </div>
                </section>
                <Space content="" bgimg="./images/gpimg1.jpg" />
                <section className='causes'>
                    <h2>
                        Our Causes<span></span>
                    </h2>
                    <h3>Come work with us</h3>
                    <div className='container'>
                        <div className='cause' >
                            <img src='./images/kidimg1.jpg' alt=''/>
                            <h3>Kids to School Project</h3>
                            <p>foster and promote good heath, psychological and social economic prosperity of orphans while 
                            protecting their rights</p>
                        </div>
                         <div className='cause' >
                            <img src='./images/kidimg1.jpg' alt=''/>
                            <h3>Poverty Eradication Project</h3>
                            <p>We help parents start businesses and provide startup items like seeds, animals or even startup capital.</p>
                        </div>
                         <div className='cause' >
                            <img src='./images/kidimg1.jpg' alt=''/>
                            <h3>Career Guidence</h3>
                            <p>We provide kids with guidence from trained professionals in </p>
                        </div>
                    </div>
                </section>
                <Space content="Every child has a right to go to school" bgimg="./images/kidimg1.jpg" />
                <section className='vol'>
                    <h2>
                        Partner<span></span>
                    </h2>
                    <h3>
                        Come Partner with us</h3>
                    <div className='container'></div>
                </section>
                 <Space content="" bgimg="./images/gpimg2.jpg" />
                <Map />
            </main>
            <Footer />
        </>
    );
}


export default Index;






