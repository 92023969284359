import React, { useState, useEffect } from 'react';
import TopNav from './Top-nav/topnav';
import { Link } from 'react-router-dom';
import './header.css';

const Header = () => {
    const [scrolled, setScrolled] = useState(true);

    const handleScroll = () => {
        if (window.scrollY < 100) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <header data-testid={`header-1`}>
            <TopNav scrolled={!scrolled} />
            <section
                className='top-menu'
                style={scrolled ? { height: '40px' } : { height: '0' }}
            >
                <div className='container menu'>
                    <div className='donation'>
                        No One Has Ever Become Poor By Giving!
                    </div>
                    <section className='contact'>
                        <div className='phone'>
                            <i class='fa fa-phone'></i>Phone: +256785124031{' '}
                        </div>
                        <div className='email'>
                            <i class='fa fa-envelope'></i>info@damucharityfoundation.com
                        </div>
                    </section>
                </div>
            </section>
            <section
                className='bottom-menu'
                style={
                    scrolled
                        ? { backgroundColor: 'rgba(0,0,0,0.7)' }
                        : { backgroundColor: 'rgba(0,0,0,1)' }
                }
            >
                <div className='container menu'>
                    <div className='logo'>
                        <img src='./logo.png' alt='Damu charity foundation' />
                    </div>
                    <nav>
                        <ul>
                            <li>
                                <Link to='/'> Home</Link>
                            </li>
                            <li>
                                <Link to='/about'>About</Link>
                            </li>
                            <li>
                                <Link to='/projects'>Projects</Link>
                            </li>
                            <li>
                                <Link to='/team'>Team</Link>
                            </li>
                            <li>
                                <Link to='/volunteers'>Volunteers</Link>
                            </li>
                            <li>
                                <Link to='/contact'>Contact</Link>
                            </li>
                           
                        </ul>
                    </nav>
                </div>
            </section>
        </header>
    );
};

export default Header;
